@font-face {
    font-family: 'Nunito';
    src: url(../fonts/Nunito-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url(../fonts/Nunito-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url(../fonts/Nunito-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url('../images/banner.jpg') no-repeat fixed center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}