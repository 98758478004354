/*  ------------------------ 
    Main Screen
    ------------------------    */

.container-fluid {
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.33);
}

/*  ------------------------ 
    Headers & fonts
    ------------------------    */


@media only screen and (max-width: 769px) {
    h1 {
        font-size: 4rem !important;
    }
}

@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 5rem !important;
    }
}

h1 {
    color: white;
    font-size: 3rem !important;
    font-weight: 700;
}

h3 {
    color: white;
}

h4 {
    font-size: 1.25rem !important;
}

p.lead {
    font-size: 1.1rem;
    font-weight: 400;
}

.font-weight-bold {
    font-weight: 600;
}

@media only screen and (min-width: 1500px) {
    .text {
        margin-right: 50px;
        margin-left: 13em;
        margin-top: 50px;
    }
}

/*  ------------------------ 
    INTRODUCTION
    ------------------------    */

.bullet {
    color: #1685A1;
    margin-right: 8px;
}

#lnk-website {
    font-weight: 600 !important;
    border-radius: 0 !important;
}

.Introduction {
    width: 75%;
}

@media only screen and (max-width: 769px) {
    .text {
        margin: auto;
    }

    h1, h3 {
        text-align: center;
    }
    .Introduction {
        width: 100% !important;
    }
}

/*  ------------------------ 
    FORM
    ------------------------    */

.form-control {
    color: white !important;
    background: rgba(22, 133, 161, 0.3) !important;
    outline: 0;
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
}

    .form-control::placeholder {
        color: white !important;
        opacity: 0.5;
    }

    .form-control:focus {
        outline-style: none;
        box-shadow: none;
    }

.form-label {
    margin-top: 10px;
    font-weight: 600;
}

.btn-primary {
    font-weight: 600 !important;
    border-radius: 0 !important;
    background: #1685A1 !important;
    border-color: #1685A1 !important;
}

form ul {
    padding-top: 12px;
    color: red;
    font-weight: 500;
}

#alertSuccess, #alertFormError {
    font-size: 18px;
    font-weight: 500;
}

.btn-submit {
    padding: .5rem 1rem;
    font-size: 1rem;
}

.btn-submit:hover {
    background-color: lightgray !important;
    color: black;
}

/*  ------------------------ 
    FOOTER
    ------------------------    */
#serviceRow {
    background-color: #1685A1;
    padding-top: 3rem;
    padding-bottom: 150px;
    margin-top: 8rem;
}

.img-wrapper {
    position: absolute;
    bottom: -325px;
    right: 30%;
}

.img-container {
    position: relative;
}
.img-footer {
    width: 700px;
}

hr {
    color: white;
    height: 3px !important;
    opacity: 1 !important;
}

a#lnkPrivacy {
    font-weight: 600 !important;
    color: white !important;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
}

.company-name {
    font-size: 16px !important;
}

.address {
    font-size: 14px !important;
}
